import CoreApi from '../../core-api'
import { EMPTY_EMAIL_ID, INVALID_EMAIL_ID, isNotEmptyEmailId } from '../../../../utils/utils'
import _ from 'lodash'
import RemoteApi from '../../../../panels/commons/remote-api'
import {
  EmailConfig as DomainEmailConfig,
  EmailResponse,
  EmptyState,
  FormEmailSettings,
  SendTo,
  UserEmail,
} from '@wix/ambassador-wix-form-builder-web/http'
import { Form as DomainForm } from '@wix/ambassador-forms-v2-form/types'
import { withBi } from '../../decorators'
import { handleError } from '../../../forms-editor-app/monitoring'
import { EVENTS } from '../../../../constants/bi'
import { emptyStateValue, getEmptyStateFromSendToDefinition, getRecipients } from './utils'
import { v4 as uuid } from 'uuid'
import Experiments from '@wix/wix-experiments'

export type GetEmailsResponse = {
  email: string
  emailId: string
  failedToSave?: boolean
}[]

export default class EmailsNotificationsApi {
  private biLogger: BILogger
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private remoteApi: RemoteApi
  private ravenInstance
  private experiments: Experiments

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.experiments = experiments
  }

  public getEmails(emailIds: string[]) {
    if (!emailIds.length) {
      return []
    }
    const validEmailIds = _.filter(emailIds, (emailId) => emailId !== INVALID_EMAIL_ID)
    const isThereNonEmptyEmail = _.some(validEmailIds, isNotEmptyEmailId)

    if (!isThereNonEmptyEmail) {
      return validEmailIds.map((emailId) => ({ emailId, email: '' }))
    }

    return this.remoteApi.getEmailsById(validEmailIds)
  }

  private _getSelectedUsersFromOtherEmails(
    otherEmails: EmailResponse[],
    siteUsersData: UserEmail[],
    inboxOptOut: boolean,
    emptyState: EmptyState,
  ) {
    if (inboxOptOut || !otherEmails || emptyStateValue(emptyState)) {
      return null
    }
    const siteUsersEmails = _.map(siteUsersData, (user) => user.email)
    const emails = _.map(otherEmails, (otherEmail) => otherEmail.email)

    const hasOtherEmails = _.some(emails, (email) => !_.includes(siteUsersEmails, email))
    if (hasOtherEmails) {
      return null
    }

    return _(siteUsersData)
      .filter((user) => _.includes(emails, user.email))
      .map((user) => user.userId)
      .value()
  }

  private _getEntitiesFromSettings(emailSettings: FormEmailSettings) {
    const sendTo = emailSettings?.sendTo
    const emptyState = emailSettings?.emptyState

    const emailIds = sendTo?.emails?.emailIds ?? (emptyState === EmptyState.EMAILS ? [] : undefined) // TODO: remove once specs.crm.FormsBizEmailsApiUpgrade is merged
    const emails = sendTo?.emails?.emails ?? (emptyState === EmptyState.EMAILS ? [] : undefined)
    const selectedSiteUserIds =
      sendTo?.users?.userIds ?? (emptyState === EmptyState.USERS ? [] : undefined)
    const isOwner = sendTo?.owner
    if ((_.isArray(emailIds) || _.isArray(emails)) && _.isArray(selectedSiteUserIds)) {
      throw Error('emails and users should not be configured together')
    }

    return {
      emails: emails?.map((email) => ({ emailId: uuid(), email })),
      emailIds,
      selectedSiteUserIds,
      isOwner,
      emptyState,
    }
  }

  private async _selectedEmailsAndUsers({
    emailSettings,
    inboxOptOut,
    siteUsersData,
    withUpgradedEmails,
  }: {
    emailSettings: FormEmailSettings
    inboxOptOut: boolean
    siteUsersData: UserEmail[]
    withUpgradedEmails: boolean
  }) {
    const owner = _.find(siteUsersData, (user) => user.isOwner)
    const {
      emailIds,
      selectedSiteUserIds,
      emptyState,
      isOwner,
      emails: emailsFromSettings,
    } = this._getEntitiesFromSettings(emailSettings)

    if (withUpgradedEmails) {
      if (_.isArray(emailsFromSettings)) {
        let emails = emailsFromSettings

        if (isOwner) {
          emails = [{ emailId: '', email: owner.email }, ...emails]
        }

        const selectedSiteUsersIdsFromOtherEmails = this._getSelectedUsersFromOtherEmails(
          emails,
          siteUsersData,
          inboxOptOut,
          emptyState,
        )

        if (selectedSiteUsersIdsFromOtherEmails) {
          return {
            emails: null,
            selectedSiteUsersIds: selectedSiteUsersIdsFromOtherEmails,
          }
        }
        return { emails, selectedSiteUsersIds: null }
      }
    } else {
      if (_.isArray(emailIds)) {
        let emails = await this.getEmails(emailIds)

        if (isOwner) {
          emails = [{ emailId: '', email: owner.email }, ...emails]
        }

        const selectedSiteUsersIdsFromOtherEmails = this._getSelectedUsersFromOtherEmails(
          emails,
          siteUsersData,
          inboxOptOut,
          emptyState,
        )

        if (selectedSiteUsersIdsFromOtherEmails) {
          return {
            emails: null,
            selectedSiteUsersIds: selectedSiteUsersIdsFromOtherEmails,
          }
        }
        return { emails, selectedSiteUsersIds: null }
      }
    }

    const ownerWithoutUsers = isOwner && !_.isArray(selectedSiteUserIds)

    return {
      emails: null,
      selectedSiteUsersIds: ownerWithoutUsers ? [owner.userId] : selectedSiteUserIds,
    }
  }


  public async createEmailConfigFromConfig({
    componentRef,
    componentConnection,
  }: {
    componentRef?: ComponentRef
    componentConnection?: ComponentConnection
  }): Promise<DomainEmailConfig> {
    const {
      sendTo: { emails, owner, users },
    } = await this.createEmailsSettingsFromConfig({
      componentRef,
      componentConnection,
      withUpgradedEmails: false, // at the moment this is not supported at publish site (this.experiments.enabled('specs.crm.FormsBizEmailsApiUpgrade'))
    })
    if (emails && owner) {
      return {
        sendToOwnerAndEmails: {
          emailIds: emails.emailIds,
        },
      }
    }
    if (emails) {
      return {
        sendToEmails: {
          emailIds: emails.emailIds,
        },
      }
    }

    if (owner) {
      return {
        sendToOwner: {},
      }
    }

    if (users) {
      return {
        sendToContributors: {
          userIds: users.userIds,
        },
      }
    }
  }

  public async createEmailsSettingsFromConfig({
    componentRef,
    componentConnection,
    withUpgradedEmails,
  }: {
    componentRef?: ComponentRef
    componentConnection?: ComponentConnection
    withUpgradedEmails: boolean
  }): Promise<FormEmailSettings> {
    const connection =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))
    const config = connection?.config
    const createSendTo = async (): Promise<SendTo> => {
      const { inboxOptOut, emailIds, emailId, secondEmailId, selectedSiteUsersIds } = config || {}
      const actualEmailId = emailIds || [emailId, secondEmailId]
      const recipients = getRecipients(actualEmailId)

      if (!_.isBoolean(inboxOptOut) || inboxOptOut) {
        const emailsObj =
          recipients.sendToOwner && !recipients.emailIds.length
            ? {}
            : withUpgradedEmails
            ? {
                emails: {
                  emails: (await this.getEmails(recipients.emailIds)).map((e) => e.email),
                },
              }
            : {
                emails: {
                  emailIds: recipients.emailIds,
                },
              }

        return {
          ...(recipients.sendToOwner ? { owner: {} } : {}),
          ...emailsObj,
        }
      } else {
        if (!selectedSiteUsersIds) {
          if (recipients.sendToOwner) {
            return {
              owner: {},
            }
          }
        }

        return {
          users: {
            userIds: selectedSiteUsersIds || [],
          },
        }
      }
    }
    const sendTo = await createSendTo()
    return {
      sendTo,
      emptyState: getEmptyStateFromSendToDefinition(sendTo, withUpgradedEmails),
    }
  }

  public async getEmailsAndSiteUsers(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
    form?: DomainForm,
  ): Promise<{
    emails?: EmailResponse[]
    siteUsersData: UserEmail[]
    selectedSiteUsersIds?: string[]
    inboxOptOut?: boolean
  }> {
    const formSchema =
      form || (await this.coreApi.settings.getForm(componentRef, componentConnection))
    const withUpgradedEmails = this.experiments.enabled('specs.crm.FormsBizEmailsApiUpgrade')
    let emailSettingsSchema = formSchema?.formMetadata?.emailSettings

    const connection =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))
    const config = _.get(connection, 'config')

    let inboxOptOut = config.inboxOptOut
    
    if (!emailSettingsSchema) {
      emailSettingsSchema = await this.createEmailsSettingsFromConfig({
        componentRef,
        componentConnection,
        withUpgradedEmails,
      })
      await this.remoteApi.formServiceClient.updateEmailSettings({
        publicId: formSchema.id,
        revision: _.toNumber(formSchema.revision),
        emailSettings: emailSettingsSchema,
        inboxNotificationOptOut: !!inboxOptOut,
      })
    } else {
      inboxOptOut = formSchema?.attributes?.afterSubmitSettings?.inboxNotificationOptOut?.enabled
    }
    const siteUsersData = await this.remoteApi.getSiteUsersData()

    const emailsAndUsers = await this._selectedEmailsAndUsers({
      emailSettings: emailSettingsSchema,
      inboxOptOut,
      siteUsersData,
      withUpgradedEmails,
    })
    return {
      ...emailsAndUsers,
      inboxOptOut,
      siteUsersData,
    }
  }
}
